import { GrabLinks } from "./GrabLinks";
export class Grab {
    constructor() {
        this.distance = 100;
        this.links = new GrabLinks();
    }
    get lineLinked() {
        return this.links;
    }
    set lineLinked(value) {
        this.links = value;
    }
    get line_linked() {
        return this.links;
    }
    set line_linked(value) {
        this.links = value;
    }
    load(data) {
        if (!data) {
            return;
        }
        if (data.distance !== undefined) {
            this.distance = data.distance;
        }
        this.links.load(data.links ?? data.lineLinked ?? data.line_linked);
    }
}
